import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import DataInitializer from '@copilot/common/utils/dataInitializer';
import { Config } from '@copilot/common/config';
import Store from './store';
import Router from './router/teamsRouter';
import { appInsights } from '@copilot/common/components/snippets/applicationInsights';
import { PermissionsProvider } from '@copilot/common/hooks/permission';
import AppDataInitializer from '@copilot/common/utils/dataInitializer/app';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { IntercomProvider } from 'react-use-intercom';
import ErrorBoundary from '@copilot/common/components/containers/errorBoundary';
import LogRocketProvider from '@copilot/common/components/snippets/logRocket';
import AppcuesProvider from '@copilot/common/components/snippets/appcues';
import AuthenticationProvider from '@copilot/common/utils/authenticationProvider';
import Apollo from '@copilot/common/components/snippets/apollo';
import MixpanelProvider from '@copilot/common/components/snippets/mixpanel/mixpanel';
import { SurvicateProvider } from '@copilot/common/components/snippets/survicate/provider';
import { Switch, Route } from 'react-router';
import { OAuthPopup } from '@copilot/common/utils/oauth';
import CopilotThemeProvider from '../copilot-theme-provider';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { LAUNCH_DARKLY_ID, WELL_KNOWN_TENANT } from '@copilot/data/config/routes';

ReactDOM.render(
	<Provider store={Store.ReduxStore}>
		<LDProvider
			clientSideID={LAUNCH_DARKLY_ID}
			context={{
				key: 'anonymous',
				custom: {
					well_known_tenant: WELL_KNOWN_TENANT,
				},
			}}
		>
			<ConnectedRouter history={Store.History}>
				<Switch>
					<Route exact component={() => <OAuthPopup />} path="/verify" />
					<Route path="*">
						<CopilotThemeProvider>
							<AppInsightsContext.Provider value={appInsights.reactPlugin}>
								<IntercomProvider appId={Config.intercomAppId} autoBoot>
									<AuthenticationProvider>
										<AppDataInitializer>
											<Apollo>
												<PermissionsProvider>
													<DataInitializer isTeamUser>
														<LogRocketProvider
															appId={Config.logRocketAppId}
															isEnabled={Config.isLogRocketEnabled}
														>
															<MixpanelProvider
																isEnabled={Config.isMixpanelEnabled}
																token={Config.mixpanelToken}
															>
																<SurvicateProvider
																	isEnabled={
																		Config.isSurvicateEnabled
																	}
																	workspaceKey={
																		Config.survicateKey
																	}
																>
																	<AppcuesProvider
																		appId={Config.appcuesAppId}
																		isEnabled={
																			Config.isAppcuesEnabled
																		}
																	>
																		<ErrorBoundary message="Something went wrong. Please refresh the page and try again">
																			<Router />
																		</ErrorBoundary>
																	</AppcuesProvider>
																</SurvicateProvider>
															</MixpanelProvider>
														</LogRocketProvider>
													</DataInitializer>
												</PermissionsProvider>
											</Apollo>
										</AppDataInitializer>
									</AuthenticationProvider>
								</IntercomProvider>
							</AppInsightsContext.Provider>
						</CopilotThemeProvider>
					</Route>
				</Switch>
			</ConnectedRouter>
		</LDProvider>
	</Provider>,
	document.getElementById('root')
);
