import { Typography } from 'antd';
import {
	MAIN_PAGE_CONTENT_TEST_ID,
	MY_INBOX_DESCRIPTION,
	MY_INBOX_TITLE,
	TEAMS_INBOX_DESCRIPTION,
	TEAMS_INBOX_TITLE,
	UP_SALE_COPY,
} from './constants';
import styles from './inboxPageV2.module.less';
import InboxPageV2FilterSection from './components/inboxV2FilterSection';
import InboxV2TabsSection from './components/inboxPageV2TabsSection';
import InboxPageV2RestrictedPlaceholder from './components/restricted/inboxPageV2RestrictedPlaceholder';

type InsightsPageV2Props = {
	showUpSell?: boolean;
	isTeamInboxView?: boolean;
	isAccessRestricted?: boolean;
};

/**
 * Top level presentational component for InboxPageV2, it is responsible for composing the page layout
 */
export default function InboxPageV2({
	showUpSell,
	isTeamInboxView,
	isAccessRestricted,
}: InsightsPageV2Props) {
	if (showUpSell) {
		//TODO move into separate component https://cassia.atlassian.net/browse/COPILOT-7698
		return <Typography.Text>{UP_SALE_COPY}</Typography.Text>;
	}

	if (isAccessRestricted) {
		return <InboxPageV2RestrictedPlaceholder />;
	}

	return (
		<div data-testid={MAIN_PAGE_CONTENT_TEST_ID} className={styles.pageWrapper}>
			<div className={styles.pageHeaderSectionWrapper}>
				<div className={styles.pageHeaderSection}>
					<Typography.Title level={4} className={styles.pageTitle}>
						{isTeamInboxView ? TEAMS_INBOX_TITLE : MY_INBOX_TITLE}
					</Typography.Title>
					<Typography.Text className={styles.pageDescription}>
						{isTeamInboxView ? TEAMS_INBOX_DESCRIPTION : MY_INBOX_DESCRIPTION}
					</Typography.Text>
					<InboxPageV2FilterSection />
				</div>
			</div>
			<InboxV2TabsSection />
		</div>
	);
}
