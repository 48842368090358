import { Button, Card, Divider, Flex, Space, Spin, Tooltip, Badge } from 'antd';
import { ContactAvatar } from '@copilot/common/components/componentModels/activities/conversationWrapper/item/contact-avatar';
import Text from 'antd/lib/typography/Text';
import isEmpty from 'lodash/isEmpty';
import {
	CheckOutlined,
	ClockCircleOutlined,
	LinkedinFilled,
	RightOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useState } from 'react';
import {
	calcDisplayMessage,
	calcIntentTagOffset,
	getFormattedRelativeDate,
	isExpandButtonVisible,
} from '@copilot/common/pages/inboxV2/components/card/util';
import {
	InboxV2CardHeaderProps,
	InboxV2CardProps,
	NEW_MESSAGE_CARD,
	REMINDER_CARD,
} from '@copilot/common/pages/inboxV2/components/card/types';
import {
	CARD_ACTION_TOOLTIPS,
	COLLAPSE_MESSAGE_COPY,
	EXPAND_MESSAGE_COPY,
	MARK_READ_TEST_ID,
	SENT_MESSAGE_PREFIX,
	SET_REMINDER_TEST_ID,
	VIEW_LINKEDIN_TEST_ID,
} from '@copilot/common/pages/inboxV2/components/card/constants';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import styles from './card.module.less';
import DropdownReminderSelector from '@copilot/common/components/selector/reminder/dropdownSelector/dropdownReminderSelector';
import { UseRemindersHandlers } from '@copilot/common/components/drawer/wrappers/contact/useReminders/types';
import { createLinkedInProfileUrl } from '@copilot/common/contacts/helpers';
import { InboxMessageSubmissionHandler } from '../../types';
import { ReminderStatus } from '@copilot/common/pages/inbox/data/types';
import { REMINDER_STATUSES } from '@copilot/common/pages/inbox/data/constant';
import { getFormattedDate } from '@copilot/common/utils/dateFormat';
import moment, { utc } from 'moment';
import { UnitOfTime } from '@copilot/common/utils/time';
import { getPluralizedEnding } from '@copilot/common/utils/stringFormat';
import SmartTextEditor from './smartTextEditor';
import {
	INBOX_CARD_MARK_READ_TRACKING_ID,
	INBOX_CARD_SET_REMINDER_TRACKING_ID,
	INBOX_CARD_VIEW_CONVERSATION_TRACKING_ID,
	INBOX_CARD_VIEW_LI_PROFILE_TRACKING_ID,
} from '@copilot/common/tracking/userpilotEventConsts';
import isNil from 'lodash/isNil';
import { AiTag } from '@copilot/common/components/ai/aiTag/aiTag';
import { getIntentAnalysisLabelDisplayValue } from '@copilot/common/store/models/intentAnalysis/util';
import { AI_TAG_SIZES } from '@copilot/common/components/ai/aiTag/types';

function CardHeader({ contactName, company, position }: InboxV2CardHeaderProps) {
	return (
		<Flex className={styles.cardHeaderSection} gap={'small'}>
			<ContactAvatar contactName={contactName} type="inbox" />
			<Flex vertical>
				<Text className={styles.nameText}>{contactName}</Text>
				{!isEmpty(position) && !isEmpty(company) && (
					<Text className={styles.jobText}>{`${position} @ ${company}`}</Text>
				)}
			</Flex>
		</Flex>
	);
}

/**
 * Converts the given date string to the reminder date copy expected
 */
function createReminderDateCopy(input: string): string {
	// To handle the edge case in which the old reminder was due but the timestamp wasn't persisted
	if (input === '0001-01-01T00:00:00') return '';
	const now = utc().local();
	const date = utc(input).local();

	if (now.startOf(UnitOfTime.day).isSame(date.startOf(UnitOfTime.day))) return 'today';
	const numOfDays = now.diff(date, UnitOfTime.day);
	return `${numOfDays} day${getPluralizedEnding(numOfDays)} ago`;
}

function CardReminderStatus({
	reminderType,
	reminderTimestamp,
}: {
	reminderType: ReminderStatus;
	reminderTimestamp: string;
}) {
	if (reminderType == REMINDER_STATUSES.Due) {
		return (
			<Space>
				<ClockCircleOutlined className={styles.expiredReminder} />
				<Text className={styles.expiredReminder}>
					This reminder was due {createReminderDateCopy(reminderTimestamp)}
				</Text>
			</Space>
		);
	}
	return (
		<Space>
			<ClockCircleOutlined className={styles.upcomingReminder} />
			<Text>
				You set a reminder for {getFormattedDate(reminderTimestamp, 'MM/DD/YYYY HH:mm')}
			</Text>
		</Space>
	);
}

/**
 * TODO: Implement the actions
 * (https://cassia.atlassian.net/browse/COPILOT-7754, https://cassia.atlassian.net/browse/COPILOT-7768)
 */
function CardAction({
	cardType,
	onViewConversation,
	cancelReminder: handleCancelReminder,
	setReminderClick,
	setReminder: handleSetReminder,
	message,
	onRemove: handleRemove,
	reminder,
}: InboxV2CardProps & UseRemindersHandlers) {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	async function onSetReminder(date: moment.Moment, isNewReminder: boolean) {
		setIsLoading(true);
		await handleSetReminder(message.threadId, date, isNewReminder);
		setIsLoading(false);
	}

	async function onCancelReminder() {
		setIsLoading(true);
		await handleCancelReminder(message.threadId);
		setIsLoading(false);
	}

	async function onRemove() {
		setIsLoading(true);
		await handleRemove();
		setIsLoading(false);
	}

	const hasAlias = !isEmpty(message.linkedInAlias);
	const hasReminder = !isEmpty(reminder?.reminderTimestamp);
	return (
		<div className={styles.actionWrapper}>
			{isLoading && <Spin indicator={<LoadingOutlined />} />}
			<Tooltip
				title={
					hasAlias
						? CARD_ACTION_TOOLTIPS.VIEW_LINKEDIN
						: CARD_ACTION_TOOLTIPS.LINKED_IN_NOT_ACCESSIBLE
				}
			>
				<Button
					type={'text'}
					icon={<LinkedinFilled />}
					href={
						hasAlias
							? createLinkedInProfileUrl(message.linkedInAlias as string)
							: undefined
					}
					disabled={!hasAlias || isLoading}
					target="_blank"
					referrerPolicy="no-referrer"
					data-testid={VIEW_LINKEDIN_TEST_ID}
					data-tracking-id={INBOX_CARD_VIEW_LI_PROFILE_TRACKING_ID}
				/>
			</Tooltip>
			<Tooltip title={CARD_ACTION_TOOLTIPS.SET_REMINDER}>
				<div
					data-testid={SET_REMINDER_TEST_ID}
					data-tracking-id={INBOX_CARD_SET_REMINDER_TRACKING_ID}
				>
					<Badge dot={hasReminder && cardType !== REMINDER_CARD}>
						<DropdownReminderSelector
							isReminderSet={hasReminder}
							onUpdate={(date, isNewReminder) =>
								void onSetReminder(date, isNewReminder)
							}
							onSetReminderClick={setReminderClick}
							onCancel={() => void onCancelReminder()}
							isIconOnly
							buttonType="text"
							buttonClass={styles.cardButton}
							buttonIcon={<ClockCircleOutlined />}
							disabled={isLoading}
							selectedDate={
								hasReminder ? moment(reminder?.reminderTimestamp) : undefined
							}
						/>
					</Badge>
				</div>
			</Tooltip>
			{cardType === NEW_MESSAGE_CARD && (
				<Tooltip title={CARD_ACTION_TOOLTIPS.READ}>
					<Button
						type={'text'}
						icon={<CheckOutlined />}
						data-testid={MARK_READ_TEST_ID}
						onClick={() => void onRemove()}
						disabled={isLoading}
						data-tracking-id={INBOX_CARD_MARK_READ_TRACKING_ID}
					>
						{CARD_ACTION_TOOLTIPS.READ}
					</Button>
				</Tooltip>
			)}
			{cardType === REMINDER_CARD && (
				<Button
					type={'text'}
					icon={<CheckOutlined />}
					onClick={() => void onRemove()}
					disabled={isLoading}
				>
					{CARD_ACTION_TOOLTIPS.REMINDER_COMPLETE}
				</Button>
			)}
			<Button
				type={'text'}
				icon={<RightOutlined />}
				onClick={onViewConversation}
				disabled={isLoading}
				data-tracking-id={INBOX_CARD_VIEW_CONVERSATION_TRACKING_ID}
			>
				{CARD_ACTION_TOOLTIPS.VIEW_CONVERSATION}
			</Button>
		</div>
	);
}

/**
 * Component for a inbox card. Contains the logic to show applicable elements based on card type.
 * @param cardType type of the card (New message or reminder message)
 * @param message the inbox message item
 */
export default function InboxV2Card({
	cardType,
	message,
	onViewConversation,
	cancelReminder,
	setReminderClick,
	setReminder,
	reminder,
	onRemove,
	onSubmitInboxMessage,
	showIntent,
}: InboxV2CardProps &
	UseRemindersHandlers & {
		onSubmitInboxMessage: InboxMessageSubmissionHandler;
	}) {
	const [isExpandedMessageVisible, setIsExpandedMessageVisible] = useState<boolean>(false);
	const isLastMessageReceived = isEqual(message.lastReceived, message.lastMessage);
	const { intentAnalysis } = message;
	const lastMessage =
		isLastMessageReceived || isUndefined(message.lastSent)
			? message.lastReceived
			: message.lastSent;

	function onSubmitMessage(msgText: string) {
		onSubmitInboxMessage({
			threadId: message.threadId,
			msgText,
			campaignId: message.campaignId,
		});
	}

	return (
		<Card className={styles.cardWrapper}>
			<Space direction={'vertical'} className={styles.cardContent}>
				<Flex justify={'space-between'} align={'center'}>
					<CardHeader
						contactName={message.contactName}
						company={message.company}
						position={message.position}
					/>
					<CardAction
						cardType={cardType}
						message={message}
						onViewConversation={onViewConversation}
						cancelReminder={cancelReminder}
						setReminderClick={setReminderClick}
						setReminder={setReminder}
						onRemove={onRemove}
						reminder={reminder}
					/>
				</Flex>
				<Flex justify={'space-between'} align={'flex-start'} gap={8}>
					<div>
						<Paragraph
							className={
								isLastMessageReceived
									? styles.receivedMessageContent
									: styles.messageContent
							}
						>
							{`${
								isLastMessageReceived ? '' : SENT_MESSAGE_PREFIX
							}${calcDisplayMessage(
								lastMessage.message,
								isExpandedMessageVisible,
								calcIntentTagOffset(
									intentAnalysis?.majorLabel?.aiLabel,
									intentAnalysis?.minorLabel?.aiLabel
								)
							)}`}
						</Paragraph>
						{isExpandButtonVisible(lastMessage.message) && (
							<Button
								type="link"
								onClick={() =>
									setIsExpandedMessageVisible(!isExpandedMessageVisible)
								}
								className={styles.messageExpandButton}
							>
								{isExpandedMessageVisible
									? COLLAPSE_MESSAGE_COPY
									: EXPAND_MESSAGE_COPY}
							</Button>
						)}
					</div>
					{showIntent && (
						<Flex justify={'flex-end'} align={'center'} gap={8}>
							{!isNil(intentAnalysis?.majorLabel?.aiLabel) && (
								<AiTag size={AI_TAG_SIZES.SMALL} isReadonly>
									{getIntentAnalysisLabelDisplayValue(
										intentAnalysis?.majorLabel?.aiLabel
									)}
								</AiTag>
							)}
							{!isNil(intentAnalysis?.minorLabel?.aiLabel) && (
								<AiTag size={AI_TAG_SIZES.SMALL} isReadonly>
									{getIntentAnalysisLabelDisplayValue(
										intentAnalysis?.minorLabel?.aiLabel
									)}
								</AiTag>
							)}
						</Flex>
					)}
				</Flex>
				<div>
					<Text className={styles.messageMetadata}>
						{getFormattedRelativeDate(lastMessage.timestamp)}
					</Text>
					<Divider type={'vertical'} />
					<Text className={styles.messageMetadata}>{message.campaignName}</Text>
					<Divider type={'vertical'} />
					<Text className={styles.messageMetadata}>{message.orgMemberName}</Text>
				</div>
				<Divider className={styles.horizontalDivider} />
				{cardType === REMINDER_CARD && !isUndefined(reminder) && (
					<CardReminderStatus
						reminderTimestamp={reminder.reminderTimestamp}
						reminderType={reminder.reminderType}
					/>
				)}
				<SmartTextEditor
					onSubmit={onSubmitMessage}
					isLoading={message.isSubmitting}
					receivedMessage={message}
				/>
			</Space>
		</Card>
	);
}
