// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".em74RYyuFJ1oxBF2XgVR .anticon-loading {\n  width: 2em;\n  height: 2em;\n}\n.tabypwORNjebBRb0XULx > * {\n  font-size: 2em;\n}\n.cb7fSXB1GFWrcBxBiJEJ {\n  height: 100vh;\n  width: 100vw;\n}\n", "",{"version":3,"sources":["webpack://./components/app-loader/appLoader.module.less"],"names":[],"mappings":"AAAA;EAEE,UAAA;EACA,WAAA;AAAF;AAIA;EACC,cAAA;AAFD;AAKA;EACC,aAAA;EACA,YAAA;AAHD","sourcesContent":[".loaderIndicator {\n\t:global(.anticon-loading) {\n\t\twidth: 2em;\n\t\theight: 2em;\n\t}\n}\n\n.loaderIcon > * {\n\tfont-size: 2em;\n}\n\n.appLoader {\n\theight: 100vh;\n\twidth: 100vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderIndicator": "em74RYyuFJ1oxBF2XgVR",
	"loaderIcon": "tabypwORNjebBRb0XULx",
	"appLoader": "cb7fSXB1GFWrcBxBiJEJ"
};
export default ___CSS_LOADER_EXPORT___;
