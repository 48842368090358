import { FilterOption } from '@copilot/common/components/filters/types';
import {
	ALL_SENTIMENTS,
	ContextFilterDictionary,
	DUE_REMINDER,
	InboxV2ContextData,
	INTERESTED_SENTIMENT,
	MAYBE_SENTIMENT,
	NOT_INTERESTED_SENTIMENT,
	ReminderFilter,
	SEARCH_CONTEXTS,
	SentimentFilter,
	UPCOMING_REMINDER,
} from './types';
import { DEFAULT_ACTIVE_ORGANIZATION_FILTER } from '@copilot/common/components/filters/organizationFilterSection/constants';

/**
 * The context we start on by default (eg what tab)
 */
export const DEFAULT_SEARCH_CONTEXT = SEARCH_CONTEXTS.ALL_MESSAGES_CONTEXT;

/**
 * Default debounce time for text search (when debouncedSearch is enabled)
 */
const DEFAULT_SEARCH_DEBOUNCE_TIME = 1000;

/**
 * Default search terms for each context (eg each tab)
 */
export const DEFAULT_SEARCH_TERM_DICTIONARY = {
	ALL_MESSAGES: '',
	REMINDERS: '',
	NEW_MESSAGES: '',
};

/**
 * Default filters for each context (eg each tab)
 */
export const DEFAULT_CONTEXT_FILTER_DICTIONARY: ContextFilterDictionary = {
	REMINDERS: DUE_REMINDER,
	NEW_MESSAGES: ALL_SENTIMENTS,
	ALL_MESSAGES: false,
};

/**
 * Options for the sentiment drop-down filter, used in New message tab
 */
export const SENTIMENT_FILTER_OPTIONS: FilterOption<SentimentFilter>[] = [
	{ value: ALL_SENTIMENTS, label: 'All' },
	{ value: INTERESTED_SENTIMENT, label: 'Interested' },
	{ value: NOT_INTERESTED_SENTIMENT, label: 'Not Interested' },
	{ value: MAYBE_SENTIMENT, label: 'Maybe' },
];

/**
 * Options for the reminder drop-down filter, used in Reminders tab
 */
export const REMINDER_FILTER_OPTIONS: FilterOption<ReminderFilter>[] = [
	{ value: DUE_REMINDER, label: 'Due' },
	{ value: UPCOMING_REMINDER, label: 'Upcoming' },
];

/**
 * Default global filter for the InboxV2, can also be used for testing to provide sensible defaults.
 */
export const DEFAULT_INBOX_V2_GLOBAL_FILTER = {
	...DEFAULT_ACTIVE_ORGANIZATION_FILTER,
	tags: [],
};

/**
 * Handlers for use reminders functionalities
 */
export const DEFAULT_USE_REMINDER_HANDLERS = {
	setReminderClick: () => {},
	setReminder: () => {
		return Promise.resolve();
	},
	cancelReminder: () => {
		return Promise.resolve();
	},
};

/**
 * Default context for the InboxV2, can also be used for testing to provide sensible defaults.
 */
export const DEFAULT_INBOX_V2_CONTEXT: InboxV2ContextData = {
	//OPTIONS
	campaignOptions: { active: [], inactive: [] },
	teamMemberOptions: [],
	sentimentFilterOptions: SENTIMENT_FILTER_OPTIONS,
	reminderFilterOptions: REMINDER_FILTER_OPTIONS,
	tagOptions: [],
	nurtureCampaigns: [],
	//STATE
	activeFilter: DEFAULT_INBOX_V2_GLOBAL_FILTER,
	searchTerm: '',
	contextFilterDictionary: DEFAULT_CONTEXT_FILTER_DICTIONARY,
	searchContext: DEFAULT_SEARCH_CONTEXT,
	isLoading: false,
	//HANDLERS
	onCampaignFilterChange: () => {},
	onTeamMemberFilterChange: () => {},
	onTagFilterChange: () => {},
	onSearchTermChange: () => {},
	onSearchContextChange: () => {},
	onSubmitSearch: () => {},
	onNewMessagesContextFilterChange: () => {},
	onRemindersContextFilterChange: () => {},
	onAllMessagesContextFilterChange: () => {},

	onClearFilters: () => {},
	onLoadMore: () => {},
	onViewConversation: () => {},
	onSubmitInboxMessage: () => {},
	onMarkAsUnread: () => {
		return Promise.resolve();
	},
	//Reminders Handlers
	...DEFAULT_USE_REMINDER_HANDLERS,
	onDeleteMessages: () => {
		return Promise.resolve();
	},
	onMoveThreadsToNurtureCampaign() {
		return Promise.resolve();
	},
	onMarkAsRead: () => {
		return Promise.resolve();
	},
	onMarkReminderAsComplete: () => {
		return Promise.resolve();
	},
	onApplyTags: () => {
		return Promise.resolve();
	},
	//DATA
	messages: [],
	numUnreadMessages: 0,
	numUrgentMessages: 0,
	numReminders: 0,
	hasMore: false,
	//CONFIG
	searchTermDebounceTime: DEFAULT_SEARCH_DEBOUNCE_TIME,
	useDebounceSearch: false,
};
