import { SEARCH_CONTEXTS, SearchContext } from './context/types';

/**
 * Determine what the default search context should be if none is set in the query param
 */
export function getDefaultSearchContext(isIntentAnalysisFeatureEnabled: boolean) {
	return isIntentAnalysisFeatureEnabled
		? SEARCH_CONTEXTS.URGENT_LEADS_CONTEXT
		: SEARCH_CONTEXTS.ALL_MESSAGES_CONTEXT;
}

/**
 * Determine the searchContext based on the set tab + feature toggles
 * @param tab
 * @param isIntentAnalysisFeatureEnabled
 * @returns
 */
export function getFeatureAwareSearchContext(
	tab: SearchContext,
	isIntentAnalysisFeatureEnabled: boolean
) {
	if (isIntentAnalysisFeatureEnabled && tab === SEARCH_CONTEXTS.UNREAD_MESSAGES_CONTEXT) {
		return SEARCH_CONTEXTS.URGENT_LEADS_CONTEXT;
	} else if (!isIntentAnalysisFeatureEnabled && tab === SEARCH_CONTEXTS.URGENT_LEADS_CONTEXT) {
		return SEARCH_CONTEXTS.UNREAD_MESSAGES_CONTEXT;
	}
	return tab;
}
