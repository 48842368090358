import { JSX, useCallback, useEffect, useState } from 'react';
import { Badge, Dropdown, Flex, Menu, Space, Spin } from 'antd';
import {
	DashboardOutlined,
	HourglassOutlined,
	InboxOutlined,
	NotificationOutlined,
	PlusOutlined,
	SettingOutlined,
	TeamOutlined,
	UploadOutlined,
	LoadingOutlined,
	ScheduleOutlined,
} from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router';
import {
	canCreateCampaignTeam,
	displayCampaignOptionsSideNav,
	getCampaignTypeIcon,
	getSimpleCampaignNameValidator,
} from '@copilot/common/utils/campaign';
import { getIcon } from '@copilot/common/components/menus/sidebar';
import isNil from 'lodash/isNil';
import partition from 'lodash/partition';
import { createCaseInsensitiveSorter } from '../../../../utils/common/sort';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import isString from 'lodash/isString';
import styles from './sidebar.module.less';
import { useCampaigns, useCreateCampaign } from '@copilot/common/hooks/campaign';
import { Campaign, CampaignStatuses } from '../../../../hooks/campaign/campaignTypes';
import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import modalManager from '@copilot/common/utils/modalManager';
import { CampaignType } from '@copilot/data/responses/interface';
import { CampaignDefaultName } from '@copilot/common/utils/constant';
import { SidebarNotifications, useSidebarNotifications } from './hooks';
import { useCopilotTheme } from '@copilot/teams/copilot-theme-provider';
import { InboxDisplayType } from '@copilot/data/requests/models';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useFetchV2 } from '@copilot/common/hooks/common';
import { AccountManager } from '@copilot/data';
import useTeamsPageSideBarContext from '@copilot/teams/src/useTeamsPageSideBarContext';
import { MEETING_BOOKED_COUNT_REFRESH_CALLBACK } from '@copilot/teams/src/context/constants';
import { Config } from '@copilot/common/config';
import { AgencyCode } from '@copilot/common/config/constant';
import { RocketOutlined as RocketOutlinedIcon } from '@ant-design/icons/lib/icons';
import { IMeetingStatus, useDetectedMeetingsCountQuery } from '@copilot/data/graphql/_generated';
import debounce from 'lodash/debounce';

const Paths = {
	OrganizationHub: '/',
	Inbox: '/inbox',
	TeamInbox: '/team-inbox',
	MeetingsBooked: '/meetings-booked',
	Outbox: '/outbox',
	Campaign: '/campaign',
	AllCampaigns: '/allcampaigns',
	Sent: '/sent',
	Connections: '/connections',
	Settings: '/settings',
	AdminHub: '/adminhub',
	CreateCampaign: '/campaign/create',
	Referral: '/referral',
} as const;

/**
 * Get the open and active keys for the sidebar to highlight and expand the proper menu items
 * @param path The path of the current page
 * @returns The open and active keys
 */
function getOpenAndActiveKeys(path: string): { openKeys: string[]; activeKeys: string[] } {
	let openKeys: string[] = [];
	let activeKeys: string[] = [path];
	const [_, root, ..._pathSegments] = path.split('/');
	const pathRoot = `/${root}`;
	switch (pathRoot) {
		case Paths.Settings:
			activeKeys = [pathRoot];
			break;
		case Paths.Campaign: {
			openKeys = [Paths.Campaign];
			break;
		}
	}
	return { openKeys, activeKeys };
}

/**
 * Combine a label with the count of notifications and a color for the badge
 * @param label The label we want to display
 * @param count The count of notifications
 * @param color The color of the badge
 * @returns
 */
function getLabelWithNotificationCount(
	label: string,
	count: number,
	color: string,
	showLoader = false
): JSX.Element {
	return (
		<Flex justify="space-between" align="center">
			<span>{label}</span>
			{showLoader ? (
				<Spin indicator={<LoadingOutlined />} />
			) : (
				<> {count > 0 && <Badge count={count} color={color} overflowCount={999} />}</>
			)}
		</Flex>
	);
}

/**
 * Get the inbox notification count
 * @param inboxType The type of inbox view shown for the user
 * @param count The count of notifications in the inbox object
 * @returns
 */
function getInboxNotificationCount(inboxType: InboxDisplayType, count: number): number | undefined {
	return inboxType === InboxDisplayType.Email && count > 0 ? count : undefined;
}

/**
 *
 * @param notifications
 * @returns
 */
function getOutboxNotificationCountAndColor(
	notifications: Record<string, number>,
	{ pendingColor, errorColor }: Record<'pendingColor' | 'errorColor', string>
): { count: number; color: string } | undefined {
	const count =
		notifications[SidebarNotifications.Pending] + notifications[SidebarNotifications.Error];
	const color = notifications[SidebarNotifications.Error] > 0 ? errorColor : pendingColor;
	return count > 0 ? { count, color } : undefined;
}

/**
 * Side bar for the prospecting app
 * @returns
 */
export function ProspectingToolSidebar(): JSX.Element {
	const history = useHistory();
	const location = useLocation();
	const { openKeys, activeKeys } = getOpenAndActiveKeys(location.pathname);
	const notifications = useSidebarNotifications();
	const theme = useCopilotTheme();

	const [detectedMeetingCount, setDetectedMeetingCount] = useState<number>(0);

	function onRefreshMeetingsBookedCount() {
		//first optimistically assume we successfully decremented the count (eg a detected meeting was confirmed or denied)

		//then ask the server for the actual count and update if successful/valid
		refetchCount().then((res) => {
			const newCount = res.data?.campaignConnectionsByMeetings?.count;
			if (typeof newCount === 'number') {
				setDetectedMeetingCount(newCount);
			}
		});
	}

	const { refetch: refetchCount } = useDetectedMeetingsCountQuery({
		variables: {
			input: {
				meeting: { status: IMeetingStatus.Detected },
				pagination: { page: 0, pageSize: 0 },
			},
		},
		onCompleted(data) {
			const newCount = data?.campaignConnectionsByMeetings?.count;
			if (typeof newCount === 'number') {
				setDetectedMeetingCount(newCount);
			}
		},
	});

	const debouncedOnRefetchMeetingsBookedCount = useCallback(
		debounce(onRefreshMeetingsBookedCount, 200),
		[refetchCount]
	);

	const { registerCallback } = useTeamsPageSideBarContext();

	useEffect(() => {
		registerCallback(MEETING_BOOKED_COUNT_REFRESH_CALLBACK, () => {
			debouncedOnRefetchMeetingsBookedCount();
		});
	}, []);

	// Get members
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);

	// Create menu items for 5 campaigns
	const campaigns = useCampaigns();
	const campaignMenuItems = toCampaignMenuItems(campaigns);

	const createCampaign = useCreateCampaign();
	function handleCampaignCreation(campaignType: CampaignType) {
		modalManager.openCampaignCreationModal({
			nameEditorValidator: getSimpleCampaignNameValidator(campaigns),
			onCreate: async (name: string) => {
				await createCampaign(name, campaignType);
			},
		});
	}

	let sysAdminItems: MenuItemType[] = [];
	if (activeMember?.isSysAdmin === true) {
		sysAdminItems = [
			{
				label: 'Admin Hub',
				icon: <DashboardOutlined />,
				key: Paths.AdminHub,
			},
		];
	}

	let orgAdminItems: MenuItemType[] = [];
	if (activeMember?.isOrgAdmin === true) {
		orgAdminItems = [
			{
				label: 'Organization Hub',
				icon: <DashboardOutlined />,
				key: Paths.OrganizationHub,
			},
		];
	}

	let campaignCreationItems: MenuItemType[] = [];
	if (!isNil(activeMember) && canCreateCampaignTeam(activeMember)) {
		campaignCreationItems = [
			{
				key: Paths.CreateCampaign,
				label: (
					<Dropdown
						dropdownRender={() => displayCampaignOptionsSideNav(handleCampaignCreation)}
						trigger={['click']}
					>
						<Space>
							{CampaignDefaultName.NewCampaign}
							<PlusOutlined />
						</Space>
					</Dropdown>
				),
			},
		];
	}

	const notificationDisplayColor: string = theme['@notification-display-color'];
	const actionsCount = activeMember?.isOrgAdmin
		? getInboxNotificationCount(
				InboxDisplayType.Email,
				notifications[SidebarNotifications.Actions]
		  )
		: 0;
	const memberActionsCount = getInboxNotificationCount(
		InboxDisplayType.Email,
		notifications[SidebarNotifications.MemberActions]
	);
	const inboxItem = {
		label: getLabelWithNotificationCount(
			'My Inbox',
			memberActionsCount ?? 0,
			notificationDisplayColor
		),
		icon: <InboxOutlined />,
		key: Paths.Inbox,
	};

	const teamInboxItem = {
		label: getLabelWithNotificationCount(
			'Team Inbox',
			actionsCount ?? 0,
			notificationDisplayColor
		),
		icon: <InboxOutlined />,
		key: Paths.TeamInbox,
	};

	const [{ data }, getCurrentAccount] = useFetchV2(AccountManager.getCurrentAccount);
	useEffect(() => {
		getCurrentAccount();
	}, []);
	const isTeamInboxShown = data?.isTeamAccount ?? false;

	const inboxItems = isTeamInboxShown ? [inboxItem, teamInboxItem] : [inboxItem];

	const outboxCountData = getOutboxNotificationCountAndColor(notifications, {
		pendingColor: notificationDisplayColor,
		errorColor: '#fbc02d',
	});

	const items = [
		...sysAdminItems,
		...orgAdminItems,
		...inboxItems,
		{
			label: isNil(outboxCountData)
				? 'Outbox'
				: getLabelWithNotificationCount(
						'Outbox',
						outboxCountData.count,
						outboxCountData.color
				  ),
			icon: <HourglassOutlined />,
			key: Paths.Outbox,
		},
		{
			label: 'My Campaigns',
			key: Paths.Campaign,
			icon: <NotificationOutlined />,
			children: [
				{
					label: 'All Campaigns',
					key: Paths.AllCampaigns,
				},
				...campaignMenuItems,
				...campaignCreationItems,
			],
		},
		{
			label: 'Sent',
			icon: <UploadOutlined />,
			key: Paths.Sent,
		},
		{
			label: 'Connections',
			icon: <TeamOutlined />,
			key: Paths.Connections,
		},
		{
			label: getLabelWithNotificationCount(
				'Meetings booked',
				detectedMeetingCount,
				notificationDisplayColor
			),
			icon: <ScheduleOutlined />,
			key: Paths.MeetingsBooked,
		},
		{
			label: 'Settings',
			icon: <SettingOutlined />,
			key: Paths.Settings,
		},
		...(Config.isAgency && Config.agencyCode === AgencyCode.cleverly
			? [
					{
						key: Paths.Referral,
						onClick: () => {
							window.open(Config.referralURL);
						},
						icon: <RocketOutlinedIcon />,
						label: 'Referral payout',
					},
			  ]
			: []),
	].filter((item) => !isNil(item));

	function handleMenuItemClick(item: MenuInfo) {
		if (item.key !== Paths.CreateCampaign) {
			const shouldOpenNewTab = item.domEvent.ctrlKey || item.domEvent.metaKey;
			shouldOpenNewTab ? window.open(item.key) : history.push(item.key);
		}
	}

	return (
		<Menu
			items={items}
			mode="inline"
			onClick={handleMenuItemClick}
			className={styles.sidebar}
			selectedKeys={activeKeys}
			defaultOpenKeys={openKeys}
		/>
	);
}

function toCampaignMenuItems(campaigns: ReadonlyArray<Campaign>): ReadonlyArray<MenuItemType> {
	const cleaned = campaigns
		.filter((campaign) => !isNil(campaign.name) && !isNil(campaign.type))
		.map((campaign) => ({
			status: campaign.status,
			label: isString(campaign.name) ? campaign.name : '',
			icon: !isNil(campaign.type) ? getIcon(getCampaignTypeIcon(campaign.type)) : undefined,
			key: `/campaign/${campaign.id}`,
		}))
		.sort(createCaseInsensitiveSorter<{ label: string }>((item) => item.label));

	const [enabled, rest] = partition(
		cleaned,
		(campaign) => campaign.status === CampaignStatuses.Enabled
	);
	return [...enabled, ...rest].slice(0, 5);
}
