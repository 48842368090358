import { useState } from 'react';
import InboxPageV2 from './inboxPageV2';
import InboxV2Context from './context/inboxV2Context';
import {
	ContextFilterDictionary,
	InboxV2GlobalFilter,
	isSearchContext,
	ReminderFilter,
	SEARCH_CONTEXTS,
	SearchContext,
	SentimentFilter,
} from './context/types';
import {
	DEFAULT_CONTEXT_FILTER_DICTIONARY,
	DEFAULT_INBOX_V2_CONTEXT,
	DEFAULT_INBOX_V2_GLOBAL_FILTER,
} from './context/constants';
import { IncludeExcludeFilterOption } from './components/includeExcludeFilter/types';
import useInboxV2Data from './useInboxV2Data';
import ContactDrawer from '@copilot/common/components/drawer/wrappers/contact/drawer';
import { InboxMessage } from '../inbox/ui/types';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import useRemindersV2 from '@copilot/common/components/drawer/wrappers/contact/useReminders/useRemindersV2';
import { useInboxPageCardViewTracking } from '../inbox/tracking/cardView';
import { useInboxPageListViewTracking } from '../inbox/tracking/emailView';
import { useAllOrganizationMembers } from '../../hooks/organizationMember/index';
import { RolesWithTeamInboxAccess } from './constants';
import useMessageActions from './useMessageActions';
import { useFeatureFlags } from '@copilot/common/hooks/useFeatureFlags/useFeatureFlags';
import { getDefaultSearchContext, getFeatureAwareSearchContext } from './util';

type InsightsPageV2SmartProps = {
	isTeamInboxView?: boolean;
};

//TODO Put all business logic in here for now (filtering, sorting, etc)
export function InboxPageV2Smart({ isTeamInboxView = false }: InsightsPageV2SmartProps) {
	const {
		messages = [],
		isLoading,
		searchParams,
		onSearchContextChange: handleSearchContextChange,
		campaignOptions,
		teamMemberOptions,
		tagOptions,
		onActiveFilterChange,
		unreadMessagesCount,
		remindersCount,
		urgentMessagesCount,
		onSubmitSearch: handleSubmitSearch,
		onUpdateSentimentFilter,
		onUpdateReminderFilter,
		onUpdateAllMessagesFilter,
		hasMore,
		onLoadMore,
		activeMember,
		activeImpersonator,
		onSubmitInboxMessage,
		nurtureCampaigns,
	} = useInboxV2Data({ isActiveMemberOnly: !isTeamInboxView });

	const { isIntentAnalysisFeatureEnabled } = useFeatureFlags();

	const {
		deleteMessagesByThreadId,
		markAsReadByThreadId,
		markReminderAsComplete,
		markAsUnReadByThreadId,
		applyTagsToMessages,
		moveThreadsToNurtureCampaign,
	} = useMessageActions();

	// Current search context (corresponds to the selected tab)
	const [searchContext, setSearchContext] = useState<SearchContext>(
		isSearchContext(searchParams.tab)
			? getFeatureAwareSearchContext(searchParams.tab, isIntentAnalysisFeatureEnabled)
			: getDefaultSearchContext(isIntentAnalysisFeatureEnabled)
	);

	const handleInboxCardViewTracking = useInboxPageCardViewTracking('Inbox Card View');
	const handleInboxListViewTracking = useInboxPageListViewTracking('Inbox List View');
	const { setReminderClick, setReminder, cancelReminder } = useRemindersV2(
		searchContext === SEARCH_CONTEXTS.ALL_MESSAGES_CONTEXT
			? handleInboxCardViewTracking
			: handleInboxListViewTracking
	);

	const [openConversation, setOpenConversation] = useState<
		{ message: InboxMessage; idx: number } | undefined
	>();

	const organizationMembers = useAllOrganizationMembers();
	const isTeamInboxRestricted =
		(organizationMembers?.length ?? 0) <= 1 &&
		!RolesWithTeamInboxAccess.some(
			(element) => activeMember?.orgRoles.includes(element) === true
		);

	// Current filter state
	const [activeFilter, setActiveFilter] = useState<InboxV2GlobalFilter>(
		DEFAULT_INBOX_V2_GLOBAL_FILTER
	);

	const [searchTerm, setSearchTerm] = useState<string>('');

	// Maps search context to context filter state (so we can preserve context filters when switching tabs)
	const [contextFilterDictionary, setContextFilterDictionary] = useState<ContextFilterDictionary>(
		DEFAULT_CONTEXT_FILTER_DICTIONARY
	);

	function onCampaignFilterChange(value: string[]) {
		const newFilter = { ...activeFilter, campaigns: value };
		setActiveFilter(newFilter);
		onActiveFilterChange(newFilter);
	}

	function onTagFilterChange(value: IncludeExcludeFilterOption[]) {
		const newFilter = { ...activeFilter, tags: value };
		setActiveFilter(newFilter);
		onActiveFilterChange(newFilter);
	}

	function onTeamMemberFilterChange(value: string[]) {
		const newFilter = { ...activeFilter, teamMembers: value };
		setActiveFilter(newFilter);
		onActiveFilterChange(newFilter);
	}

	function onSearchTermChange(newTerm: string) {
		setSearchTerm(newTerm);
	}

	function onNewMessagesContextFilterChange(newFilter: SentimentFilter) {
		setContextFilterDictionary({ ...contextFilterDictionary, NEW_MESSAGES: newFilter });
		onUpdateSentimentFilter(newFilter);
	}

	function onRemindersContextFilterChange(newFilter: ReminderFilter) {
		setContextFilterDictionary({ ...contextFilterDictionary, REMINDERS: newFilter });
		onUpdateReminderFilter(newFilter);
	}

	function onAllMessagesContextFilterChange(newFilter: boolean) {
		setContextFilterDictionary({ ...contextFilterDictionary, ALL_MESSAGES: newFilter });
		onUpdateAllMessagesFilter(newFilter);
	}

	function onSearchContextChange(newContext: SearchContext) {
		setSearchContext(newContext);
		setContextFilterDictionary(DEFAULT_CONTEXT_FILTER_DICTIONARY);
		handleSearchContextChange(newContext);
	}

	function onClearFilters() {
		setActiveFilter(DEFAULT_INBOX_V2_GLOBAL_FILTER);
		onActiveFilterChange(DEFAULT_INBOX_V2_GLOBAL_FILTER);
	}

	function onSubmitSearch(term: string) {
		handleSubmitSearch(term);
	}

	function onViewConversation(message: InboxMessage, idx: number) {
		setOpenConversation({ message, idx });
	}

	function handleVisitedThreadsOnClose(threadIds: string[]) {
		if (isEmpty(activeImpersonator)) markAsReadByThreadId(threadIds);
	}

	// if we are using the unreplied leads filter or urgent leads filter, reload the page if we sent a message while the drawer was open
	function handleMessageSentOnClose() {
		if (
			(contextFilterDictionary.ALL_MESSAGES && !isIntentAnalysisFeatureEnabled) ||
			searchContext === SEARCH_CONTEXTS.URGENT_LEADS_CONTEXT
		)
			onLoadMore(0, messages.length);
	}

	return (
		<InboxV2Context.Provider
			value={{
				...DEFAULT_INBOX_V2_CONTEXT,
				searchContext,
				searchTerm,
				onCampaignFilterChange,
				onTagFilterChange,
				onTeamMemberFilterChange,
				onSearchTermChange,
				onSearchContextChange,
				activeFilter,
				onSubmitSearch,
				campaignOptions,
				enableTeamMemberFilter: isTeamInboxView,
				teamMemberOptions,
				tagOptions,
				numUnreadMessages: unreadMessagesCount,
				numReminders: remindersCount,
				numUrgentMessages: urgentMessagesCount,
				messages,
				isLoading,
				contextFilterDictionary,
				onNewMessagesContextFilterChange,
				onRemindersContextFilterChange,
				onAllMessagesContextFilterChange,
				onClearFilters,
				hasMore: hasMore === true,
				onLoadMore,
				onViewConversation,
				setReminderClick,
				setReminder,
				cancelReminder,
				onDeleteMessages: deleteMessagesByThreadId,
				onMarkAsRead: markAsReadByThreadId,
				onMarkAsUnread: markAsUnReadByThreadId,
				onMarkReminderAsComplete: markReminderAsComplete,
				onSubmitInboxMessage,
				onApplyTags: applyTagsToMessages,
				onMoveThreadsToNurtureCampaign: moveThreadsToNurtureCampaign,
				nurtureCampaigns,
			}}
		>
			<InboxPageV2
				isTeamInboxView={isTeamInboxView}
				isAccessRestricted={isTeamInboxView && isTeamInboxRestricted}
			/>
			{!isNil(openConversation) && !isNil(activeMember) && (
				<ContactDrawer
					id={openConversation.message.contactId}
					memberId={openConversation.message.orgMemberId}
					threadId={openConversation.message.threadId}
					threadIdx={openConversation.idx}
					isSagaInbox
					onClose={() => setOpenConversation(undefined)}
					visitedThreadsHandler={handleVisitedThreadsOnClose}
					messageSentHandler={handleMessageSentOnClose}
				/>
			)}
		</InboxV2Context.Provider>
	);
}
