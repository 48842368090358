import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Button, Flex } from 'antd';
import { OrganizationMemberManager, AuthenticationManager } from '@copilot/data';
import { OrganizationMemberActions } from '@copilot/common/store/actions/organizationMember';
import notificationManager from '@copilot/common/utils/notificationManager';
import { Config } from '@copilot/common/config';

import PasswordUpdateForm, {
	PasswordUpdateFormValues,
} from '@copilot/common/components/forms/user/passwordUpdate';
import EditableTextDisplay from '@copilot/common/components/forms/common/inputs/editableTextDisplay';
import { IOrganizationMember } from '@copilot/common/store/models/redux';
import { ERROR_ICON, OK_ICON, SYNCING_ICON } from '@copilot/common/components/icon';
import styles from './userSettings.module.less';
import { disconnectMyLinkedIn } from './serviceCalls';
import { useRegionalProxies } from '../regions/useRegionalProxies';
import { LinkedInMetaActions } from '@copilot/common/store/actions/linkedInMeta';
import { USER_SETTINGS_LINKEDIN_CONNECT_TRACKING_ID } from '@copilot/common/tracking/userpilotEventConsts';
import { useNavigateToHostedAuthUrl } from '@copilot/common/hooks/linkedin/hostedAuth/useNavigateToHostedAuthUrl';

type UserSettingsProps = {
	orgMember: IOrganizationMember;
	hideLinkedIn?: boolean;
};

/**
 * Settings config for the user
 * @param param0
 * @returns
 */
export default function UserSettings({ orgMember, hideLinkedIn }: UserSettingsProps) {
	const storeDispatch = useDispatch();
	const { currentUserRegion, hasProxySet } = useRegionalProxies({
		userVpnPort: orgMember.vpnPort,
	});

	const navigateToHostedAuthUrl = useNavigateToHostedAuthUrl(orgMember.id, window.location.href);

	const [isRefreshingSalesNav, setIsRefreshingSalesNav] = useState(false);
	const [isReconnecting, setIsReconnecting] = useState(false);
	const [isDisconnectingLinkedIn, setIsDisconnectingLinkedIn] = useState(false);

	function onUpdatePhoneNumber(phoneNumber: string) {
		OrganizationMemberManager.updateSelf({ phoneNumber }).then((member) => {
			storeDispatch(OrganizationMemberActions.loadOrganizationMember(member));
		});
	}

	async function onRefreshSalesNav() {
		try {
			setIsRefreshingSalesNav(true);
			await navigateToHostedAuthUrl();
		} catch (e) {
			notificationManager.showErrorNotification({
				message: 'Error',
				description:
					'There was an issue syncing sales navigator, please refresh and try again.',
			});
		} finally {
			// Add a brief delay to prevent the user from spamming the sync button after an error
			// Not relevant to the success case since the user is redirected to a new page
			await new Promise((resolve) => setTimeout(resolve, 1000));
			setIsRefreshingSalesNav(false);
		}
	}

	async function onDisconnectLinkedIn() {
		try {
			setIsDisconnectingLinkedIn(true);
			const updatedMember = await disconnectMyLinkedIn();
			const isLoggedIn = await OrganizationMemberManager.checkIsLoggedIn(updatedMember.id);
			if (isLoggedIn === false) {
				if (orgMember.isLiSessionInRetryState) {
					storeDispatch(OrganizationMemberActions.loadOrganizationMember(updatedMember));
				}
				storeDispatch(
					LinkedInMetaActions.loadLinkedInMeta({
						orgMemberId: updatedMember.id,
						isLoggedIn,
					})
				);
				notificationManager.showSuccessNotification({
					message: 'Success',
					description: 'LinkedIn was successfully disconnected',
				});
			} else {
				throw new Error('Failed to disconnect LinkedIn');
			}
		} catch (e) {
			notificationManager.showErrorNotification({
				message: 'Error',
				description:
					'There was an issue disconnecting LinkedIn, please refresh and try again.',
			});
		}
		setIsDisconnectingLinkedIn(false);
	}

	function onUpdatePassword(values: PasswordUpdateFormValues) {
		AuthenticationManager.setPassword(
			values.oldPassword,
			values.newPassword,
			values.confirmPassword
		)
			.then(() =>
				notificationManager.showSuccessNotification({
					message: 'Saved',
					description: 'Your password has been updated.',
				})
			)
			.catch(() => {
				notificationManager.showErrorNotification({
					message: 'Failed',
					description: 'Please refresh the page and try again',
				});
			});
	}

	async function onReconnect() {
		try {
			setIsReconnecting(true);
			const isLoggedIn = await OrganizationMemberManager.checkIsLoggedIn(orgMember.id);
			if (isLoggedIn) {
				notificationManager.showInfoNotification({
					message: 'Already Connected',
					description: 'Your account is already connected to LinkedIn.',
				});
				storeDispatch(
					LinkedInMetaActions.loadLinkedInMeta({
						orgMemberId: orgMember.id,
						isLoggedIn,
					})
				);
				return;
			}
			await navigateToHostedAuthUrl();
		} catch (e) {
			console.error("Couldn't generate LinkedIn auth URL", e);
			notificationManager.showErrorNotification({
				message:
					'Oops! Something went wrong. Please try again in a moment, or contact support if this problem persists.',
				description: "Couldn't generate LinkedIn auth URL",
			});
		} finally {
			// Add a brief delay to prevent the user from spamming the reconnect button after an error
			// Not relevant to the success case since the user is redirected to a new page
			await new Promise((resolve) => setTimeout(resolve, 1000));
			setIsReconnecting(false);
		}
	}
	
	return (
		<div className={styles.userSettingsWrapper}>
			<Flex gap="small">
				<label>First Name</label>
				<div>{orgMember.firstName}</div>
			</Flex>
			<Flex gap="small">
				<label>Last Name</label>
				<div>{orgMember.lastName}</div>
			</Flex>
			<Flex gap="small">
				<label>Email</label>
				<div>{orgMember.email}</div>
			</Flex>
			<Flex gap="small">
				<label>Phone number</label>
				<EditableTextDisplay
					value={orgMember.phoneNumber}
					confirmHandler={onUpdatePhoneNumber}
					confirmOnBlur
				>
					{orgMember.phoneNumber}
				</EditableTextDisplay>
			</Flex>
			<Flex gap="small">
				<label>Region</label>
				{hasProxySet ? currentUserRegion : 'No region set'}
			</Flex>
			{!hideLinkedIn && (
				<>
					<Flex gap="small">
						<label>LinkedIn session status</label>
						{orgMember.isLinkedInLoggedIn || orgMember.isLiSessionInRetryState ? (
							<div>
								<Flex gap="small" align="center">
									{orgMember.isLinkedInLoggedIn ? OK_ICON : SYNCING_ICON}
									{orgMember.isLinkedInLoggedIn ? 'Connected' : 'Syncing'}
									<Button
										size="small"
										onClick={() => void onDisconnectLinkedIn()}
										loading={isDisconnectingLinkedIn}
									>
										Disconnect
									</Button>
								</Flex>
								<div className={styles.statusDescription}>
									{orgMember.isLinkedInLoggedIn ? (
										<>
											Your LinkedIn is currently connected to our platform,
											automation will run if you have an active LinkedIn Sales
											Navigator subscription.
										</>
									) : (
										<>
											We are attempting to sync your LinkedIn account.
											Outgoing messages will be paused and uploading search
											lists will be temporarily unavailable until the
											connection is established.
										</>
									)}
								</div>
							</div>
						) : (
							<div>
								<Flex gap="small" align="center">
									{ERROR_ICON}
									Disconnected
									<Button
										size="small"
										onClick={() => void onReconnect()}
										loading={isRefreshingSalesNav || isReconnecting}
										data-tracking-id={
											USER_SETTINGS_LINKEDIN_CONNECT_TRACKING_ID
										}
									>
										Reconnect
									</Button>
								</Flex>
								<div className={styles.statusDescription}>
									Your LinkedIn is currently not connected to our platform,
									preventing automation. Click "Reconnect" to connect your
									LinkedIn account.
								</div>
							</div>
						)}
					</Flex>
					{orgMember.isLinkedInLoggedIn && (
						<Flex gap="small">
							<label>Sales Navigator status</label>
							{orgMember.hasInvalidSalesNavSubscription ? (
								<div>
									<Flex gap="small" align="center">
										{ERROR_ICON}
										Paused
										<Button
											size="small"
											onClick={() => void onRefreshSalesNav()}
											loading={isRefreshingSalesNav}
										>
											Sync Sales Navigator
										</Button>
									</Flex>
									<div className={styles.statusDescription}>
										Automation has paused because we can't detect a valid
										LinkedIn Sales Navigator subscription for your account.
										Please ensure you have an active LinkedIn Sales Navigator
										subscription and then click "Sync Sales Navigator"
									</div>
								</div>
							) : (
								<div>
									<Flex gap="small" align="center">
										{OK_ICON} Active
									</Flex>
									<div className={styles.statusDescription}>
										Your LinkedIn Sales Navigator subscription is marked as
										active and automation is running
									</div>
								</div>
							)}
						</Flex>
					)}
				</>
			)}
			{!Config.isB2CEnabled && (
				<>
					<Divider />
					<PasswordUpdateForm onSubmit={onUpdatePassword} />
				</>
			)}
		</div>
	);
}
