import {
	FlagOutlined,
	UnorderedListOutlined,
	ClockCircleOutlined,
	LoadingOutlined,
	FireOutlined,
} from '@ant-design/icons';
import { Badge, Flex, Spin, Switch, Tabs, Typography } from 'antd';
import InboxV2CardList from './listing/inboxV2CardList';
import InboxV2EmailList from './listing/inboxV2EmailList';
import styles from '../inboxPageV2.module.less';
import useInboxV2 from '../useInboxV2';
import { TAB_LOADER_TEST_ID } from '../constants';
import InboxV2ListSelectBox from './listing/inboxV2ListSelectBox';
import { SEARCH_CONTEXTS, SearchContext } from '../context/types';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { NEW_MESSAGE_CARD, URGENT_LEAD_CARD, REMINDER_CARD } from './card/types';
import { InboxMessageType } from '../../inbox/data/types';
import {
	INBOX_CARD_SENTIMENT_FILTER_TRACKING_ID,
	INBOX_UNREPLIED_TOGGLE_TRACKING_ID,
} from '@copilot/common/tracking/userpilotEventConsts';
import { BetaTag } from '@copilot/common/components/icon/betaTag';

/**
 * Tab section for InboxV2, each tab has it's own listing strategy and implicit filters (eg Reminders vs New Messages)
 */
export default function InboxV2TabsSection() {
	const {
		messages,
		hasFilter,
		numUnreadMessages,
		numReminders,
		numUrgentMessages,
		searchContext,
		onSearchTabChange,
		isTabLoading,
		sentimentFilterOptions,
		reminderFilterOptions,
		getRemindersContextFilter,
		getNewMessagesContextFilter,
		onRemindersContextFilterChange,
		onNewMessagesContextFilterChange,
		onAllMessagesContextFilterChange,
		hasMore,
		isLoading,
		onLoadMore,
		onViewConversation,
		onDeleteMessages,
		onMarkReminderAsComplete,
		onMarkAsRead,
		isIntentAnalysisFeatureEnabled,
	} = useInboxV2();
	const {
		ALL_MESSAGES_CONTEXT,
		UNREAD_MESSAGES_CONTEXT,
		REMINDERS_CONTEXT,
		URGENT_LEADS_CONTEXT,
	} = SEARCH_CONTEXTS;

	function getTabIcon(key: SearchContext) {
		if (isTabLoading(key)) {
			return (
				<Spin
					data-testid={TAB_LOADER_TEST_ID}
					indicator={<LoadingOutlined className={styles.tabSpinner} />}
				/>
			);
		}
		switch (key) {
			case ALL_MESSAGES_CONTEXT:
				return <UnorderedListOutlined />;
			case UNREAD_MESSAGES_CONTEXT:
				return <FlagOutlined />;
			case REMINDERS_CONTEXT:
				return <ClockCircleOutlined />;
			case URGENT_LEADS_CONTEXT:
				return <FireOutlined />;
			default:
				return null;
		}
	}

	/**
	 * Calcs what tabs to show based on feature toggle config, etc
	 */
	function getTabs() {
		const baseTabs = [
			{
				key: ALL_MESSAGES_CONTEXT,
				label: (
					<Flex align="center">
						{getTabIcon(ALL_MESSAGES_CONTEXT)}
						All
						{isIntentAnalysisFeatureEnabled && (
							<Badge
								count={numUnreadMessages}
								className={styles.tabBadge}
								overflowCount={999}
							/>
						)}
					</Flex>
				),
				children: (
					<InboxV2EmailList
						messages={messages}
						hasFilter={hasFilter}
						isLoading={isLoading}
						onReload={() => {}}
						canLoadMore={hasMore}
						isLoadingMore={false}
						onLoadMore={onLoadMore}
						onViewConversation={onViewConversation}
						isSelectable={true}
						extraHeaderContent={
							<Flex gap={10} align="center">
								<Switch
									onChange={onAllMessagesContextFilterChange}
									data-tracking-id={INBOX_UNREPLIED_TOGGLE_TRACKING_ID}
								/>
								<Typography.Text className={styles.listSwitchLabel}>
									{isIntentAnalysisFeatureEnabled
										? `View unread only (${numUnreadMessages})`
										: 'View unreplied only'}
								</Typography.Text>
							</Flex>
						}
						onRemove={(msgs: InboxMessageType[]) =>
							onDeleteMessages(msgs.map((m) => m.threadId))
						}
					/>
				),
			},
			{
				key: REMINDERS_CONTEXT,
				label: (
					<Flex align="center">
						{getTabIcon(REMINDERS_CONTEXT)}
						Reminders
						<Badge
							count={numReminders}
							className={styles.tabBadge}
							overflowCount={999}
						/>
					</Flex>
				),
				children: (
					<InboxV2CardList
						messages={messages.filter((m) => !isNil(m.reminder))}
						hasFilter={hasFilter}
						extraHeaderContent={
							<InboxV2ListSelectBox
								options={reminderFilterOptions}
								defaultValue={getRemindersContextFilter()}
								onChange={onRemindersContextFilterChange}
							/>
						}
						isLoading={isLoading}
						onReload={() => {}}
						canLoadMore={hasMore}
						isLoadingMore={false}
						onLoadMore={onLoadMore}
						onViewConversation={onViewConversation}
						onRemove={onMarkReminderAsComplete}
						cardType={REMINDER_CARD}
					/>
				),
			},
		];

		if (isIntentAnalysisFeatureEnabled) {
			return [
				{
					key: URGENT_LEADS_CONTEXT,
					label: (
						<Flex>
							<Flex gap={8} align="center">
								<BetaTag />
								{getTabIcon(URGENT_LEADS_CONTEXT)}
							</Flex>
							Unreplied
							<Badge count={numUrgentMessages} className={styles.tabBadge} />
						</Flex>
					),
					children: (
						<InboxV2CardList
							messages={messages}
							hasFilter={hasFilter}
							showIntents={true}
							isLoading={isLoading}
							onReload={() => {}}
							canLoadMore={hasMore}
							isLoadingMore={isLoading && !isEmpty(messages)}
							onLoadMore={onLoadMore}
							onViewConversation={onViewConversation}
							onRemove={(_) => {
								return Promise.resolve();
							}}
							cardType={URGENT_LEAD_CARD}
						/>
					),
				},
				...baseTabs,
			];
		} else {
			const [allTab, reminderTab] = baseTabs;
			return [
				allTab,
				{
					key: UNREAD_MESSAGES_CONTEXT,
					label: (
						<Flex>
							{getTabIcon(UNREAD_MESSAGES_CONTEXT)}
							Unread
							<Badge
								count={numUnreadMessages}
								className={styles.tabBadge}
								overflowCount={999}
							/>
						</Flex>
					),
					children: (
						<InboxV2CardList
							messages={messages}
							hasFilter={hasFilter}
							extraHeaderContent={
								<InboxV2ListSelectBox
									options={sentimentFilterOptions}
									defaultValue={getNewMessagesContextFilter()}
									onChange={onNewMessagesContextFilterChange}
									data-tracking-id={INBOX_CARD_SENTIMENT_FILTER_TRACKING_ID}
								/>
							}
							isLoading={isLoading}
							onReload={() => {}}
							canLoadMore={hasMore}
							isLoadingMore={isLoading && !isEmpty(messages)}
							onLoadMore={onLoadMore}
							onViewConversation={onViewConversation}
							onRemove={(msgs) => onMarkAsRead(msgs.map((m) => m.threadId))}
							cardType={NEW_MESSAGE_CARD}
						/>
					),
				},
				reminderTab,
			];
		}
	}

	return (
		<Tabs
			defaultActiveKey={searchContext}
			className={styles.tabsSection}
			onChange={onSearchTabChange}
			//prevents us from rendering non-visible tabs
			destroyInactiveTabPane
			items={getTabs()}
		/>
	);
}
