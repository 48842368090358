import moment from 'moment';
import isNil from 'lodash/isNil';
/**
 * Max # of characters to show in a message before truncating with an ellipsis in a regular card state
 */
const MESSAGE_ELLIPSIS_CHAR_COUNT = 227;

/**
 * Calculate the display message based on message area and expansion state
 * @param messageToDisplay
 * @param isExpandedMessageVisible
 * @returns
 */
export function calcDisplayMessage(
	messageToDisplay: string,
	isExpandedMessageVisible: boolean,
	intentTagOffset: number
) {
	if (isExpandedMessageVisible) return messageToDisplay;
	if (messageToDisplay.length > MESSAGE_ELLIPSIS_CHAR_COUNT) {
		return (
			messageToDisplay.substring(0, MESSAGE_ELLIPSIS_CHAR_COUNT - intentTagOffset - 3) + '...'
		);
	}
	return messageToDisplay;
}

export function calcIntentTagOffset(
	majorIntent: string | undefined,
	minorIntent: string | undefined
) {
	const PADDING_OFFSET = 12;
	let offset = 0;
	if (!isNil(majorIntent)) {
		offset += majorIntent.length + PADDING_OFFSET;
	}

	if (!isNil(minorIntent)) {
		offset += minorIntent.length + PADDING_OFFSET;
	}
	return offset;
}

/**
 * Calc if we need to display the expand message button or not
 * @param messageToDisplay
 * @returns
 */
export function isExpandButtonVisible(messageToDisplay: string) {
	return messageToDisplay.length > MESSAGE_ELLIPSIS_CHAR_COUNT;
}

/**
 * Returns a formatted date relative to local time
 * @param input the date
 */
export function getFormattedRelativeDate(input: string | number) {
	const date = moment.utc(input);
	const localDate = date.local();
	const now = moment();

	if (localDate.isSame(now, 'day')) {
		// Today: seconds/minutes/hours ago
		return localDate.fromNow();
	} else if (localDate.isSame(now, 'week')) {
		// Current week: days ago
		return localDate.fromNow();
	} else if (localDate.isSame(now, 'year')) {
		// Current year: Month + day (e.g. March 3)
		return localDate.format('MMMM D');
	} else {
		// Others: Month day, year (e.g. March 3, 2023)
		return localDate.format('MMMM D, YYYY');
	}
}
