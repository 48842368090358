import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import Intercom from '../intercom';
import Mouseflow from '../mouseflow';
import { appInsights } from '../applicationInsights';
import { OrgRoleTitles } from '@copilot/common/store/models/const/enum';
import { AuthenticationManager } from '@copilot/data';
import { useLocation } from 'react-router';
import { Config } from '@copilot/common/config';
import { AgencyCode } from '@copilot/common/config/constant';
import HubSpot from '../hubspot';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import { RootState } from '../../../store/reducers/index';
import { Userpilot } from 'userpilot';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { WELL_KNOWN_TENANT } from '@copilot/data/config/routes';

interface AppSnippetsProps {
	isTeamUser: boolean;
	mouseflowAppId: string;
	userpilotAppKey?: string;
}

const AllowedRoles = [
	OrgRoleTitles.Admin,
	OrgRoleTitles.User,
	OrgRoleTitles.Advanced,
	OrgRoleTitles.Owner,
];

export default function AppSnippets({ mouseflowAppId, userpilotAppKey }: AppSnippetsProps) {
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const activeOrg = useSelector(OrganizationSelectors.getActiveOrganization);
	const activeImpersonator = useSelector((state: RootState) => state.app.impersonator);
	const location = useLocation();
	const roleAllowed = useMemo(
		() =>
			!!activeMember?.orgRoles?.filter((r) => AllowedRoles.includes(r as OrgRoleTitles))
				?.length,
		[activeMember?.orgRoles]
	);

	const canEnableUserpilot = isString(userpilotAppKey) && Config.isUserpilotEnabled;
	const ldClient = useLDClient();

	// Setup Userpilot - run once on component creation
	useEffect(() => {
		if (!canEnableUserpilot) return;
		Userpilot.initialize(userpilotAppKey);
	}, [canEnableUserpilot]);

	// Identify active user
	useEffect(() => {
		if (
			!canEnableUserpilot ||
			isNil(activeMember) ||
			isNil(activeMember.userCreatedDate) ||
			isNil(activeOrg)
		)
			return;

		Userpilot.identify(activeMember.userId, {
			firstName: activeMember.firstName,
			lastName: activeMember.lastName,
			name: `${activeMember.firstName} ${activeMember.lastName}`,
			created_at: new Date((activeMember.userCreatedDate ?? 0) * 1000).toISOString(), //userCreatedDate is a unix timestamp (eg in seconds) so multiple by 1000 and convert to isoString
			email: activeMember.email,
			roles: activeMember.orgRoles,
			company: {
				id: activeOrg.name,
				orgId: activeOrg.id,
				name: activeOrg.name,
				maxSeats: activeOrg.maxSeats, // may be undefined
				allocatedSeats: activeOrg.seatsAllocated, // may be undefined
				isInFreeTrial: activeOrg.isFreeTrial,
				created_at: activeOrg.tenantCreatedAt,
			},
			impersonator: activeImpersonator ?? '',
		});
	}, [activeMember?.id, activeMember?.userCreatedDate, activeOrg?.id]);

	//mhofer - Identify the user for LaunchDarkly segmentation purposes so we can have more tailored feature flags if desired.
	useEffect(() => {
		if (!isNil(ldClient) && !isNil(activeMember) && !isNil(activeMember?.organization)) {
			ldClient.identify({
				key: activeMember.userId,
				name: activeMember.name,
				email: activeMember.email,
				custom: {
					organization_id: activeMember.organizationId,
					organization_member_roles: activeMember.orgRoles,
					organization_name: activeMember?.organization?.ref.name,
					well_known_tenant: WELL_KNOWN_TENANT,
				},
			});
		}
	}, [ldClient, activeMember]);

	useEffect(() => {
		if (roleAllowed && appInsights.ApplicationInsights && activeMember?.userId)
			appInsights.ApplicationInsights.setAuthenticatedUserContext(
				activeMember.userId,
				activeMember.organizationId,
				true
			);
	}, [activeMember?.userId, roleAllowed]);

	useEffect(() => {
		if (activeMember?.id) AuthenticationManager.callIdentify();
	}, [activeMember?.id, location.pathname]);

	useEffect(() => {
		Userpilot.reload();
	}, [location]);

	if (!Config.isAgency) {
		return (
			<>
				<Intercom />
				<Mouseflow appID={mouseflowAppId} />
				<HubSpot />
			</>
		);
	} else if (Config.agencyCode === AgencyCode.cleverly) {
		return <Intercom />;
	} else {
		return <></>;
	}
}
