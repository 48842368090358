import { lazy, PropsWithChildren, Suspense, useState } from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router';
import LoginLayout from '@copilot/common/pages/layouts/login';
import RouteWithPermissions from '@copilot/common/router/routeWithPermissions';
import LoginPage from '@copilot/common/pages/login';
import { OrgRoleTitles, RoleGroups } from '@copilot/common/store/models/const/enum';
import OrganizationDashboard from '@copilot/common/pages/organizationDashboard/organizationDashboardPage';
import SettingsPage from '@copilot/common/pages/settings/settingsPage';
import { createCampaignDashboard } from '@copilot/common/pages/campaignDashboard';
import AdminDashboard from '@copilot/common/pages/adminDashboard';
import PasswordRecoveryPage from '@copilot/common/pages/passwordRecovery';
import AppSnippets from '@copilot/common/components/snippets/app/appSnippets';
import OutboxPage from '@copilot/common/pages/outbox';
import ConnectionsPage from '@copilot/common/pages/connections';
import {
	createCampaignsContainerWithConfig,
	withTeamCapability,
} from '@copilot/common/pages/campaigns/ui/campaignsContainer';
import withTeamsUserType from '@copilot/common/hoc/userType/teams';
import { SentMessageContainer } from '@copilot/common/pages/sent/ui/container';
import { MOUSEFLOW_APP_ID } from '@copilot/common/components/snippets/const';
import RegistrationPage from '@copilot/common/pages/registration';
import ScrollToTop from '@copilot/common/router/scrollToTop';
import { PersonalityInsightsPage } from '@copilot/common/pages/personalityInsights/personalityInsightsPage';
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
} from '@copilot/common/utils/authenticationProvider';
import B2CSignUpPage from '@copilot/common/pages/b2csignup';
import { AccountDashboardTheme, AntdTheme, Config } from '@copilot/common/config';
import AllCampaignsPageContainer from '@copilot/common/pages/allCampaigns/allCampaignsPageContainer';
import AdminHub from '@copilot/common/pages/adminHub';
import ImpersonatorInformationPanel from '@copilot/common/pages/adminHub/ImpersonatorInformationPanel';
import HeaderSiderLayout from '@copilot/common/pages/layouts/headerSider';
import { ConfigProvider } from 'antd';
import { AccountDashboardHeaderContainer } from '@copilot/common/pages/layouts/headerSider/header/accountDashboardHeader';
import { AccountDashboardSidebar } from '@copilot/common/pages/layouts/headerSider/sidebar/accountDashboardSidebar';
import ImpersonationRedirect from '@copilot/common/pages/impersonate';
import PersonalizedInsightsPage from '@copilot/common/pages/personalizedInsightsV2';
import { InboxPageV2Smart } from '@copilot/common/pages/inboxV2/inboxPageV2Smart';
import { ProspectingToolSidebar } from '../../../common/pages/layouts/headerSider/sidebar/prospectingToolSidebar';
import { ProspectingToolHeaderContainer } from '../../../common/pages/layouts/headerSider/header/prospectingToolHeader';
import MeetingsBookedPage from '@copilot/common/pages/meetingsBooked/meetingsBookedPageSmart';
import { TeamsPageSideBarContext } from '../context/teamsPageSideBarContext';
import { BlocklistUploadWizard } from '@copilot/common/pages/organizationDashboard/blocklist/blocklistUploadWizard';
import LoadingIndicator from '@copilot/common/components/loadingIndicator/spinner';
import { USERPILOT_APP_KEY } from '@copilot/data/config/routes';

const BaseLayoutRoutes = [
	'/',
	'/campaign/:id',
	'/settings/:tab?',
	'/contacts',
	'/adminhub/:tenantId?',
	'/impersonate/:adminUserId/:impersonateeId',
];
const AccountDashboardLayoutRoutes = ['/dashboard'];
const LoginLayoutRoutes = ['/login', '/passwordrecovery', '/registration/passwordconfirmation'];
const SignUpLayoutRoutes = ['/signup'];
const roleReq = RoleGroups.Member;

const CampaignsPage = createCampaignsContainerWithConfig(withTeamCapability);
const CampaignDashboard = withTeamsUserType(createCampaignDashboard);

const withLazyComponent = (LazyComponent: any) => {
	return (props: any) => (
		<Suspense fallback={<LoadingIndicator isLoading={true} isFullScreen={true} />}>
			<LazyComponent {...props} />
		</Suspense>
	);
};

const AccountDashboardPage = withLazyComponent(
	lazy(() => import('@copilot/common/pages/accountDashboard/accountDashboardPage'))
);
const AccountDetailPage = withLazyComponent(
	lazy(() => import('@copilot/common/pages/accountDetailsPage/accountDetailsPage'))
);

const AccountCampaignsProspecting = withLazyComponent(
	lazy(() => import('@copilot/common/pages/accountCampaigns/accountCampaignsProspecting'))
);
const AccountCampaignsNurture = withLazyComponent(
	lazy(() => import('@copilot/common/pages/accountCampaigns/accountCampaignsNurture'))
);

const LayoutSwitcher = ({ children }: PropsWithChildren<{}>) => {
	return (
		<ConfigProvider prefixCls="copilot" theme={AntdTheme}>
			<AuthenticatedTemplate>
				<HeaderSiderLayout>
					<HeaderSiderLayout.Notification>
						<ImpersonatorInformationPanel />
					</HeaderSiderLayout.Notification>
					<HeaderSiderLayout.Header>
						<ProspectingToolHeaderContainer />
					</HeaderSiderLayout.Header>
					<HeaderSiderLayout.Sider>
						<ProspectingToolSidebar />
					</HeaderSiderLayout.Sider>
					<HeaderSiderLayout.Content>{children}</HeaderSiderLayout.Content>
				</HeaderSiderLayout>
			</AuthenticatedTemplate>
		</ConfigProvider>
	);
};

function Router(props: RouteProps) {
	const [callBackMap, setCallBackMap] = useState<Record<string, () => void>>({});

	function onRegisterContextCallback(funcName: string, func: () => void) {
		setCallBackMap({ ...callBackMap, [funcName]: func });
	}

	return (
		<TeamsPageSideBarContext.Provider
			value={{
				registerCallback: onRegisterContextCallback,
				callBackMap,
			}}
		>
			<ScrollToTop />
			<Switch location={props.location}>
				{Config.isB2CEnabled && (
					<Route exact path={SignUpLayoutRoutes}>
						<LoginLayout>
							<Switch>
								<RouteWithPermissions
									exact
									path="/signup"
									render={(renderProps) => <B2CSignUpPage {...renderProps} />}
									allowedRoles={RoleGroups.Guest}
									roleRedirectTo="/"
								/>
							</Switch>
						</LoginLayout>
					</Route>
				)}
				<Route exact path={LoginLayoutRoutes}>
					<UnauthenticatedTemplate>
						<LoginLayout>
							<Switch>
								<RouteWithPermissions
									exact
									path="/registration/passwordconfirmation"
									component={RegistrationPage}
									allowedRoles={RoleGroups.Guest}
									roleRedirectTo="/"
								/>
								<RouteWithPermissions
									exact
									path="/login"
									component={LoginPage}
									allowedRoles={RoleGroups.Guest}
									roleRedirectTo={{
										[OrgRoleTitles.Owner]: '/main',
										[OrgRoleTitles.Admin]: '/main',
										[OrgRoleTitles.Advanced]: '/inbox',
										[OrgRoleTitles.User]: '/inbox',
									}}
								/>
								<RouteWithPermissions
									exact
									path="/passwordrecovery"
									component={PasswordRecoveryPage}
									allowedRoles={RoleGroups.Guest}
									roleRedirectTo="/login"
								/>
							</Switch>
						</LoginLayout>
					</UnauthenticatedTemplate>
				</Route>
				<Route path={AccountDashboardLayoutRoutes}>
					<ConfigProvider prefixCls="copilot" theme={AccountDashboardTheme}>
						<AuthenticatedTemplate>
							<HeaderSiderLayout>
								<HeaderSiderLayout.Header>
									<AccountDashboardHeaderContainer />
								</HeaderSiderLayout.Header>
								<HeaderSiderLayout.Sider>
									<AccountDashboardSidebar />
								</HeaderSiderLayout.Sider>
								<HeaderSiderLayout.Content>
									<Switch>
										<RouteWithPermissions
											exact
											path="/dashboard"
											component={AccountDashboardPage}
											allowedRoles={roleReq}
										/>
										<RouteWithPermissions
											exact
											path="/dashboard/campaigns-prospecting/:view?"
											component={AccountCampaignsProspecting}
											allowedRoles={roleReq}
										/>
										<RouteWithPermissions
											exact
											path="/dashboard/campaigns-nurture/:view?"
											component={AccountCampaignsNurture}
											allowedRoles={roleReq}
										/>
										<RouteWithPermissions
											exact
											path="/dashboard/:accountId"
											component={AccountDashboardPage}
											allowedRoles={roleReq}
										/>
										<RouteWithPermissions
											exact
											path="/dashboard/:accountId/details"
											component={AccountDetailPage}
											allowedRoles={roleReq}
										/>
										<RouteWithPermissions
											exact
											path="/dashboard/:parentAccountId/:accountId/details"
											component={AccountDetailPage}
											allowedRoles={roleReq}
										/>
									</Switch>
								</HeaderSiderLayout.Content>
							</HeaderSiderLayout>
						</AuthenticatedTemplate>
					</ConfigProvider>
				</Route>
				<Route path={['/personalized-insights/:linkedInAlias']}>
					<AuthenticatedTemplate>
						<RouteWithPermissions
							exact
							path="/personalized-insights/:linkedInAlias"
							component={PersonalityInsightsPage}
							allowedRoles={roleReq}
						/>
					</AuthenticatedTemplate>
				</Route>
				<Route path={['/insights/:profileId']}>
					<AuthenticatedTemplate>
						<RouteWithPermissions
							exact
							path="/insights/:profileId"
							component={PersonalizedInsightsPage}
							allowedRoles={roleReq}
						/>
					</AuthenticatedTemplate>
				</Route>
				<Route path={['/wizard/blocklist']}>
					<AuthenticatedTemplate>
						<RouteWithPermissions
							exact
							path="/wizard/blocklist"
							component={BlocklistUploadWizard}
							allowedRoles={RoleGroups.SysAdmin}
						/>
					</AuthenticatedTemplate>
				</Route>
				<Route path={BaseLayoutRoutes}>
					<LayoutSwitcher>
						<Switch>
							<RouteWithPermissions
								exact
								path="/"
								roleBasedComponents={{
									[OrgRoleTitles.Owner]: OrganizationDashboard,
									[OrgRoleTitles.Admin]: OrganizationDashboard,
									[OrgRoleTitles.Advanced]: InboxPageV2Smart,
									[OrgRoleTitles.User]: InboxPageV2Smart,
								}}
								allowedRoles={roleReq}
							/>
							<RouteWithPermissions
								exact
								path="/campaigns"
								component={CampaignsPage}
								allowedRoles={roleReq}
							/>
							<RouteWithPermissions
								exact
								path="/allCampaigns"
								component={AllCampaignsPageContainer}
								allowedRoles={roleReq}
							/>
							<RouteWithPermissions
								exact
								path="/campaign/:campaignId?"
								allowedRoles={roleReq}
								component={CampaignDashboard}
							/>
							<RouteWithPermissions
								exact
								path="/inbox"
								component={() => <InboxPageV2Smart />}
								allowedRoles={roleReq}
							/>
							<RouteWithPermissions
								exact
								path="/team-inbox"
								component={() => <InboxPageV2Smart isTeamInboxView />}
								allowedRoles={roleReq}
							/>
							<RouteWithPermissions
								exact
								path="/meetings-booked"
								component={() => <MeetingsBookedPage />}
								allowedRoles={roleReq}
							/>
							<RouteWithPermissions
								exact
								path="/my-inbox"
								component={() => <InboxPageV2Smart />}
								allowedRoles={roleReq}
							/>
							<RouteWithPermissions
								exact
								path="/settings/:tab?"
								allowedRoles={roleReq}
								render={(routeProps) => (
									<SettingsPage {...routeProps} hideEmails hidePause />
								)}
							/>
							<RouteWithPermissions
								exact
								path="/sent"
								component={SentMessageContainer}
								allowedRoles={roleReq}
							/>
							<RouteWithPermissions
								exact
								path="/outbox"
								component={OutboxPage}
								allowedRoles={roleReq}
							/>
							<RouteWithPermissions
								exact
								path="/connections/:connectionId?"
								component={ConnectionsPage}
								allowedRoles={roleReq}
							/>
							<RouteWithPermissions
								exact
								path="/admin"
								component={AdminDashboard}
								allowedRoles={RoleGroups.SysAdmin}
							/>
							<RouteWithPermissions
								exact
								path="/adminhub/:tenantId?"
								component={AdminHub}
								allowedRoles={RoleGroups.SysAdmin}
							/>
							<RouteWithPermissions
								exact
								path="/impersonate/:adminUserId/:impersonateeId"
								component={ImpersonationRedirect}
								allowedRoles={roleReq}
							/>
							<Redirect
								to={{
									pathname: '/',
									state: {
										redirectStatus: true,
									},
								}}
							/>
						</Switch>
					</LayoutSwitcher>
				</Route>
			</Switch>
			<AppSnippets
				isTeamUser
				mouseflowAppId={MOUSEFLOW_APP_ID}
				userpilotAppKey={USERPILOT_APP_KEY}
			/>
		</TeamsPageSideBarContext.Provider>
	);
}

export default Router;
