import { Collapse, Divider, Skeleton, Space, Typography } from 'antd';
import styles from './infoPanel.module.less';
import Header from '../../wrappers/contact/header';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { Pipeline } from '../../wrappers/contact/contactPipeline/contactPipeline';
import { MeetingButton } from '../../wrappers/contact/meetingButton';
import DropdownReminderSelector from '@copilot/common/components/selector/reminder/dropdownSelector/dropdownReminderSelector';
import PersonalizedInsightsDrawerSection from '../../wrappers/contact/informationPanel/personalizedInsightsDrawerSection';
import ProspectInfo from '../../wrappers/contact/prospectInfo';
import TagsRow from '../../wrappers/contact/tags/tagsRow';
import { useState } from 'react';
import CampaignHistoryRows from '../../wrappers/contact/campaignHistoryRows';
import { UseInfoPanelV2Data } from './hooks/types';
import {
	CAMPAIGN_HISTORY_BUTTON_TEXT,
	CAMPAIGN_HISTORY_LOADER_TEST_ID,
	CAMPAIGN_HISTORY_WRAPPER_TEST_ID,
	HEADER_LOADER_TEST_ID,
	INFO_PANEL_WRAPPER_TEST_ID,
	PROSPECT_INFO_BUTTON_TEXT,
	PROSPECT_INFO_LOADER_TEST_ID,
	PROSPECT_INFO_WRAPPER_TEST_ID,
	TAGS_BUTTON_TEXT,
	TAGS_LOADER_TEST_ID,
	TAGS_WRAPPER_TEST_ID,
} from './constants';
import { useFeatureFlags } from '@copilot/common/hooks/useFeatureFlags/useFeatureFlags';

const { Title } = Typography;

type InfoPanelV2Props = UseInfoPanelV2Data;

/**
 * Component responsible for rendering the information panel in the contact drawer and coordinating its state, CRUD flows, etc
 * @returns
 */
export default function InfoPanelV2({
	// common data/state
	orgMemberId,
	isLoading,
	contact,
	campaignConnections,
	// Header data
	onChangeSelectedOrgMember,
	isTargetOpenProfile,
	targetAlias,
	// Pipeline data
	hasMessaged,
	hasReplied,
	hasBooked,
	// Meeting data
	isLoadingMeeting,
	onMeetingBookedUpdated,
	// Reminder data
	reminderDate,
	isReminderLoading,
	onUpdateReminder,
	setReminderClick,
	onCancelReminder,
	//personalized insights data
	hasAcceptedAITermsOfUse,
	isPersonalizedInsightsLoading,
	onOpenPersonalizedInsights,
	onPersonalizedInsightsNotAvailable,
	// Contact editing data
	contactInfoProps,
	//tags data
	isOwner,
	availableTags,
	connectionTags,
	onSaveTags,
	onUpdateDisplayedTags,
	//campaign history data
	isCampaignHistoryVisible,
	campaignHistory,
}: InfoPanelV2Props) {
	const [tagInputVisible, setTagInputVisible] = useState(false);
	const isLoadingHeaderData =
		isLoading || isNil(contact) || isNil(orgMemberId) || isNil(campaignConnections);
	const { isNewAiUiFeatureEnabled } = useFeatureFlags();

	return (
		<div className={styles.infoPanelWrapper} data-testid={INFO_PANEL_WRAPPER_TEST_ID}>
			<Space direction="vertical" size="middle" className={styles.headerWrapper}>
				{isLoadingHeaderData ? (
					<div data-testid={HEADER_LOADER_TEST_ID}>
						<Skeleton
							loading
							active
							className={styles.headerSkeleton}
							paragraph={{ rows: 2 }}
						/>
					</div>
				) : (
					<Header
						firstName={contact.firstName}
						isOpenProfile={isTargetOpenProfile}
						lastName={contact.lastName}
						company={contact.company}
						jobTitle={contact.title}
						selectedConnectedOption={orgMemberId}
						handleConnectedToSelect={onChangeSelectedOrgMember}
						connectedToOptions={(campaignConnections ?? []).map((contactConnect) => ({
							label: contactConnect.orgMemberName,
							value: contactConnect.orgMemberId,
						}))}
						contactAlias={targetAlias}
					/>
				)}
				<Pipeline hasMessaged={hasMessaged} hasReplied={hasReplied} hasBooked={hasBooked} />
				<Space direction="vertical" className={styles.buttonWrapper}>
					<MeetingButton
						block
						size={'large'}
						isBooked={hasBooked}
						handleMeetingClick={onMeetingBookedUpdated}
						loading={isLoadingMeeting}
						className={styles.meetingButton}
					/>
					<DropdownReminderSelector
						selectedDate={reminderDate}
						onUpdate={onUpdateReminder}
						onCancel={onCancelReminder}
						onSetReminderClick={setReminderClick}
						isReminderSet={!isNil(reminderDate)}
						buttonType="default"
						size={'large'}
						loading={isReminderLoading}
						buttonClass={styles.reminderButton}
						alignment={{ points: ['cl', 'cr'] }}
					/>
				</Space>
			</Space>
			<Divider className={styles.divider} />
			{!isNewAiUiFeatureEnabled && (
				<>
					<PersonalizedInsightsDrawerSection
						isEnabled={hasAcceptedAITermsOfUse && !isPersonalizedInsightsLoading}
						isLoadingData={isPersonalizedInsightsLoading}
						isShown
						onClick={onOpenPersonalizedInsights}
						onNotAvailable={onPersonalizedInsightsNotAvailable}
					/>
					<Divider className={styles.divider} />
				</>
			)}

			<div>
				<Collapse
					ghost
					items={[
						{
							label: (
								<Title className={styles.infoPanelTitle}>
									{PROSPECT_INFO_BUTTON_TEXT}
								</Title>
							),
							children: isNil(contactInfoProps) ? (
								<div data-testid={PROSPECT_INFO_LOADER_TEST_ID}>
									<Skeleton loading active />
								</div>
							) : (
								<div data-testid={PROSPECT_INFO_WRAPPER_TEST_ID}>
									<ProspectInfo {...contactInfoProps} />
								</div>
							),
						},
					]}
				/>
			</div>
			<Divider className={styles.divider} />
			<div className={styles.collapseWrapper}>
				<Collapse
					ghost
					items={[
						{
							label: (
								<Title className={styles.infoPanelTitle}>{TAGS_BUTTON_TEXT}</Title>
							),
							children: isNil(contact) ? (
								<div data-testid={TAGS_LOADER_TEST_ID}>
									<Skeleton loading active />
								</div>
							) : (
								<div data-testid={TAGS_WRAPPER_TEST_ID}>
									<TagsRow
										isOwner={isOwner}
										contactTags={connectionTags}
										showInput={() => setTagInputVisible(true)}
										availableTags={availableTags}
										setContactTags={onUpdateDisplayedTags}
										onSaveTags={(tags) => {
											setTagInputVisible(false);
											onSaveTags(tags);
										}}
										inputVisible={tagInputVisible}
									/>
								</div>
							),
						},
					]}
				/>
			</div>
			<Divider className={styles.divider} />
			{isCampaignHistoryVisible && (
				<>
					<Collapse
						ghost
						items={[
							{
								label: (
									<Title className={styles.infoPanelTitle}>
										{CAMPAIGN_HISTORY_BUTTON_TEXT}
									</Title>
								),
								children: isEmpty(campaignHistory) ? (
									<div data-testid={CAMPAIGN_HISTORY_LOADER_TEST_ID}>
										<Skeleton loading active />
									</div>
								) : (
									<div data-testid={CAMPAIGN_HISTORY_WRAPPER_TEST_ID}>
										<CampaignHistoryRows campaignHistory={campaignHistory} />
									</div>
								),
							},
						]}
					/>
					<Divider className={styles.divider} />
				</>
			)}
			{isNewAiUiFeatureEnabled && (
				<>
					<PersonalizedInsightsDrawerSection
						isEnabled={hasAcceptedAITermsOfUse && !isPersonalizedInsightsLoading}
						isLoadingData={isPersonalizedInsightsLoading}
						isShown
						onClick={onOpenPersonalizedInsights}
						onNotAvailable={onPersonalizedInsightsNotAvailable}
					/>
					<Divider className={styles.divider} />
				</>
			)}
		</div>
	);
}
