import { Config } from '@copilot/common/config';
import { addScriptToDocument } from '@copilot/common/utils/scripts';
import { CRMAccountApiManager } from '@copilot/data';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { useEffect, useState, useRef } from 'react';
import { OAuthCodeFlowManager } from '@copilot/common/utils/oauth';
import { OAuthResponseTypes } from '@copilot/common/utils/oauth/constants';
import { AppLoader } from '@copilot/teams/src/components/app-loader/appLoader';

interface AlbatoProps {
	tenantId: string;
}

const OAuthManager = new OAuthCodeFlowManager({
	authorizeUrl: Config.crmOAuthAuthorizeUrl,
	clientId: Config.crmOAuthClientId,
	redirectUri: Config.crmOAuthRedirectUrl,
	scope: Config.crmOAuthScope,
	responseType: OAuthResponseTypes.Code,
	extraQueryParameters: {
		prompt: 'login',
		template: Config.b2cTemplateName,
	},
	onSuccess: async (
		code: string,
		requestParams: Record<string, string | ((isReady: boolean) => void)> | undefined
	) => {
		const isRecordString = (
			record: string | ((isReady: boolean) => void)
		): record is string => {
			return typeof record === 'string';
		};
		const response = await CRMAccountApiManager.getOAuthSsoToken(code);
		if (response) {
			if (requestParams?.tenantId && isRecordString(requestParams.tenantId)) {
				await CRMAccountApiManager.createSsoConnection(
					requestParams.tenantId,
					response.access_token,
					response.refresh_token
				);
				if (
					requestParams?.ssoConnReadyCallback &&
					!isRecordString(requestParams.ssoConnReadyCallback)
				) {
					requestParams.ssoConnReadyCallback(true);
				}
			}
		}
	},
	onError: (error_) => {
		console.error('Errored', error_);
	},
});

/**
 * Checks if default SSO credentials are set, otherwise intiate OAuth to grant access
 */
async function setupSso(tenantId: string, setIsSsoConnectionReady: (isReady: boolean) => void) {
	const ssoConnection = await CRMAccountApiManager.getSsoConnection();
	if (isNil(ssoConnection) || ssoConnection.success === false || isEmpty(ssoConnection.data)) {
		OAuthManager.startAuth({
			tenantId: tenantId,
			ssoConnReadyCallback: setIsSsoConnectionReady,
		});
	} else {
		setIsSsoConnectionReady(true);
	}
}

/**
 * Helper function to call function from dynamic script for Albato iframe
 */
function callIframeResize() {
	window.iFrameResize(
		{ enablePublicMethods: true, heightCalculationMethod: 'lowestElement' },
		'#albato'
	);
}

/**
 * Helper function to retrieve access token for the Albato iframe
 * @param props tenantId
 * @returns token
 */
async function retrieveToken(tenantId: string) {
	const token = await CRMAccountApiManager.getAccountToken();
	if (isEmpty(token)) {
		try {
			const account = await CRMAccountApiManager.getAccount(tenantId);
			if (!isNil(account) && account.success) {
				await CRMAccountApiManager.refreshAccountToken(tenantId);
			}
		} catch (err: any) {
			if (err.status === 404)
				await CRMAccountApiManager.createAccount(tenantId, new Date().getTimezoneOffset());
			else console.error('Failed to get or refresh token', err);
		}

		return CRMAccountApiManager.getAccountToken();
	} else {
		return token;
	}
}

/**
 * Helper to populate the iframe url
 * @param tenantId tenantId of the account
 * @param setIframeUrl set the iframe url
 */
async function populateIframeUrl(
	tenantId: string,
	setIframeUrl: (url: string | undefined) => void,
	setIsSsoConnectionReady: (isReady: boolean) => void
) {
	const token = await retrieveToken(tenantId);
	setupSso(tenantId, setIsSsoConnectionReady);
	const url = `https://copilotai.albato-embedded.net/user/auth/token?session_token=${token}&r=/solutions/marketplace/138?view_id=1`;
	setIframeUrl(url);
}

/**
 * Snippet that loads the Albato iframe
 * @param props Albato props (account Id)
 * @returns
 */
const Albato: React.FC<AlbatoProps> = (props) => {
	const { tenantId } = props;
	const [iframeUrl, setIframeUrl] = useState<string>();
	const [isSsoConnectionReady, setIsSsoConnectionReady] = useState<boolean>(false);
	const mounted = useRef(false);

	const setSsoConnectionReady = (isReady: boolean) => {
		if (mounted.current) setIsSsoConnectionReady(isReady);
	};
	useEffect(() => {
		mounted.current = true;
		if (!Config.isAgency) populateIframeUrl(tenantId, setIframeUrl, setSsoConnectionReady);
		return () => {
			mounted.current = false;
		};
	}, [tenantId]);

	if (Config.isAgency) return null;
	if (!iframeUrl || !isSsoConnectionReady) {
		return <AppLoader />;
	} else {
		addScriptToDocument(
			'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.2/iframeResizer.min.js',
			'albato_script',
			callIframeResize
		);
		return (
			<iframe
				id="albato"
				src={iframeUrl}
				style={{
					width: '1px',
					minWidth: '100%',
					border: 'none',
					height: '1000px',
					overflow: 'hidden',
				}}
			>
   </iframe>
		);
	}
};

export default Albato;
