import { useCallback, useContext } from 'react';
import InboxV2Context from './context/inboxV2Context';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import {
	SEARCH_CONTEXTS,
	InboxV2ContextData,
	ReminderFilter,
	SentimentFilter,
	isSearchContext,
} from './context/types';
import { useFeatureFlags } from '@copilot/common/hooks/useFeatureFlags/useFeatureFlags';

type InboxV2HookData = {
	onDebouncedSearchTermChange: (newTerm: string) => void;
	hasFilter: boolean;
	onSearchTabChange: (newTab: string) => void;
	contextSearchTerm: string;
	isTabLoading: (tabKey: string) => boolean;
	getNewMessagesContextFilter: () => SentimentFilter;
	getRemindersContextFilter: () => ReminderFilter;
	isIntentAnalysisFeatureEnabled: boolean;
} & InboxV2ContextData;

/**
 * Custom hook to pass down handlers and state without prop drilling.
 * Provide additional business logic, namely any small calculations
 * to determine ui state  that don't need to be in the smart component or additional variants of the handlers/states/etc.
 * NOTE: Could also wrap the redux references directly if we want insulate the smart component as well.
 * (Doing this may negatively impact ease of testing though, so would be a trade off)
 * NOTE: Could also be broken down into sub hooks that provide more specialized functionality while wrapping the same context.
 * (Eg useInboxV2Search, useInboxV2Filters)
 */
export default function useInboxV2(): InboxV2HookData {
	const contextData = useContext(InboxV2Context);
	const { isIntentAnalysisFeatureEnabled } = useFeatureFlags();

	const {
		onSearchTermChange,
		onSubmitSearch,
		activeFilter,
		onSearchContextChange,
		onRemindersContextFilterChange,
		searchTermDebounceTime,
		searchContext,
		searchTerm,
		contextFilterDictionary,
		isLoading,
	} = contextData;

	/**
	 * Determine if the inbox has a user defined filter applied
	 * (all views will have some form of filtering applied, but we want to know if the user is actively filtering so we can show better empty states)
	 */
	function calcHasFilter() {
		const { campaigns, tags, teamMembers } = activeFilter;
		return (
			!isEmpty([...campaigns, ...tags, ...teamMembers]) || !isEmpty(getContextSearchTerm())
		);
	}

	/**
	 * Debounced version of the submitSearch handler
	 */
	const onDebouncedSubmitSearch = useCallback(
		debounce(function submitSearchDebounceWrapper(term: string) {
			onSubmitSearch(term);
		}, searchTermDebounceTime),
		[searchTermDebounceTime, onSubmitSearch]
	);

	/**
	 * Debounce the search term change handler
	 * (Example of how we can use the hook to enhance our business logic while keeping the smart/presentational components clean and simple.)
	 */
	function onDebouncedSearchTermChange(newTerm: string) {
		onSearchTermChange(newTerm);
		onDebouncedSubmitSearch(newTerm);
	}

	/**
	 * Handler for when the search tab changes, convert the tab key to a search context and call the context change handler
	 */
	function onSearchTabChange(newTabKey: string) {
		onSearchContextChange(
			isSearchContext(newTabKey) ? newTabKey : SEARCH_CONTEXTS.ALL_MESSAGES_CONTEXT
		);
	}

	/**
	 * Get the search term for the current context (active tab)
	 */
	function getContextSearchTerm() {
		return searchTerm;
	}

	function getRemindersContextFilter() {
		return contextFilterDictionary.REMINDERS;
	}

	function getNewMessagesContextFilter() {
		return contextFilterDictionary.NEW_MESSAGES;
	}

	/**
	 * Determine if the current tab should be considered loading (so we can show a spinner, etc)
	 */
	function isTabLoading(tabKey: string) {
		return isLoading && tabKey === searchContext;
	}

	return {
		...contextData,
		hasFilter: calcHasFilter(),
		onDebouncedSearchTermChange,
		onSearchTabChange,
		contextSearchTerm: getContextSearchTerm(),
		isTabLoading,
		getRemindersContextFilter,
		getNewMessagesContextFilter,
		onRemindersContextFilterChange,
		isIntentAnalysisFeatureEnabled,
	};
}
